import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {
  useAvailabilityRequestStatusQuery,
  useRequestMoreAvailabilitySlotsMutation
} from "../../../api/sdl";

interface Props {
  positionMatchId: string
}

export function AvailabilityRequestButton(props: Props) {

  const [request] = useRequestMoreAvailabilitySlotsMutation()

  const {data, refetch} = useAvailabilityRequestStatusQuery({
    variables: {positionMatchId: props.positionMatchId},
    fetchPolicy: 'no-cache'
  });

  const handleClick = async () => {
    await request({variables: {positionMatchId: props.positionMatchId}});
    await refetch();
  }

  return <Box sx={{display: "flex", justifyContent: "center", m: 1, flexDirection: "column", gap:2, textAlign: "center"}}>
    {!data?.availabilityRequestStatus &&
        <>
            <Typography variant={"body1"} sx={{textAlign: "center"}}>You can request more slots from contractor</Typography>
            <Button onClick={handleClick} variant={"contained"} size={"small"}>Request more time slots</Button>
        </>
    }

    {!data?.availabilityRequestStatus?.status && <>
        <Typography variant={"body1"} sx={{textAlign: "center"}}>You have already requested more options from the candidate. We will notify you as soon as they are added.</Typography>
    </>}
  </Box>
}