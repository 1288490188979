import {RouteComponentProps, useHistory} from "react-router-dom";
import React from "react";
import PositionForm from "../../../widget/position-form/PositionForm";
import {Box, Button, Container} from "@mui/material";
import Loading from "../../../shared/components/Loading";
import PositionView from "../../../widget/position-view/PositionView";
import ActionsMenu from "./ui/ActionsMenu";
import PublishMenu from "./ui/PublishMenu";
import useMeetingsByPositionId from "./hooks/useMeetingsByPositionId";
import usePositionById from "../../../shared/hooks/usePositionById";
import {PositionStatus} from "../../../api/sdl";
import {usePositionStatusApi} from "./hooks/usePositionStatusApi";


function PositionPage(props: RouteComponentProps<{id?: string}>) {
  const {id: positionId} = props.match.params;
  const history = useHistory();
  const [editMode, setEditMode] = React.useState(!props.match.params.id);
  const {position,  loading, called, refetch} = usePositionById(positionId);
  const meetings = useMeetingsByPositionId(positionId);

  const statusApi = usePositionStatusApi({
    refresh: () => {refetch({id: positionId})},
    positionId: positionId
  });

  const nextInterview = React.useMemo(() => {
    return meetings[0];
  }, [meetings])

  return <Box>
    <Loading in={positionId ? loading || !called : false}>
      <Container sx={{my: 4}} maxWidth={'lg'}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 3}}>
          <Button sx={{color: '#3075B8!important'}} variant={'text'} onClick={() => history.push('/positions')}>Current roles</Button>
          {!editMode &&
            <Box sx={{display: 'flex', gap: '12px'}}>
              {positionId && <PublishMenu positionId={positionId} positionStatus={position?.status} api={statusApi}/>}
              {positionId && position?.status !== PositionStatus.Open &&
                  <Button onClick={() => { statusApi.publish() }} variant={'contained'} data-test={"publish-rolw-button"}>Publish</Button> }
              {positionId && <ActionsMenu positionId={positionId} />}
              {!editMode && <Button disabled={!position} variant={'outlined'} onClick={() => setEditMode(true)}>Edit role</Button>}
            </Box>
          }
        </Box>
        {(!editMode && position) && <PositionView position={position} nextInterview={nextInterview} />}
        {!!editMode && <PositionForm onSubmit={(posId) => {
          refetch({id: posId});
          history.replace('/position/' + posId);
          setEditMode(false)
        }}
                                     id={props.match.params.id}
                                     onBackClick={() => {
                                       setEditMode(false)
                                       if (!positionId) {
                                         history.push('/positions')
                                       }
                                     }} />}
      </Container>
    </Loading>

  </Box>;
}

export default PositionPage



