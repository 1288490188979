import React, {useContext} from "react";
import useIsMobile from "../../shared/hooks/useIsMobile";
import Loading from "../../shared/components/Loading";
import {Alert, Box, Container} from "@mui/material";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import PositionView, {
  PositionViewJobDetails
} from "../position-view/PositionView";
import {CandidatePositionApplication} from "../../feature/position-apply/CandidatePositionApplication";
import {ContractorAlreadyApplied} from "../../feature/position-apply";
import PositionApproveBottomNav from "../../contractor/components/PositionApproveBottomNav";
import {PositionMatchContext} from "../../entity/position-match/context/PositionMatchContext";
import EditableUserCalendar from "../../shared/components/Calendar/EditableUserCalendar";
import {ProfileContext} from "../../shared/ProfileContext";
import {SlotAvailabilityAlert} from "./ui/SlotAvailabilityAlert";
import {MobileForwardToCalendar} from "./ui/MobileForwardToCalendar";
import {PositionViewCurrentExperience} from "../position-view/ui/PositionViewCurrentExperience";

const ContractorPositionWidget = () => {

  const isMobile = useIsMobile();
  const {
    loading,
    position,
    meetingSlotsAreAvailable,
    positionMatch,
    scheduleUpdated
  } = useContext(PositionMatchContext);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [profile] = useContext(ProfileContext)

  return <Loading in={loading}>
    <Container sx={{p: 3, my: {xs: 4, md: 3}}} maxWidth={'lg'}>
      <Box mb={2}>
        <Breadcrumbs crumbs={[{title: 'Roles', link: '/positions'}, {title: position?.title || 'Loading...'}]}/>
      </Box>

      {!isMobile && <>

          <Box mb={2}>
              <PositionView position={position!}/>
          </Box>


          <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
            {meetingSlotsAreAvailable === false && <SlotAvailabilityAlert/>}

            {(!!positionMatch?.expectedRate) && <ContractorAlreadyApplied/>}

              <EditableUserCalendar
                  onCalendarUpdate={scheduleUpdated}
                  contractorId={profile!!.contractorProfile!!.contractorId}
              />

            {!positionMatch?.expectedRate &&
                <CandidatePositionApplication position={position!} meetingSlotsAreAvailable={meetingSlotsAreAvailable}
                                              positionMatchId={positionMatch?.id}/>}
          </Box>
      </>}

      {isMobile && <>

        {tabIndex === 0 && <Box mt={2} sx={{gap: 2, display: "flex", flexDirection: "column"}}>
          {!positionMatch?.expectedRate &&
              <MobileForwardToCalendar onClick={() => setTabIndex(2)}/>}
          <PositionViewJobDetails position={position}/>
          <PositionViewCurrentExperience position={position}/>
        </Box>}
        {tabIndex === 1 && <>
          {!positionMatch?.expectedRate &&
            <MobileForwardToCalendar onClick={() => setTabIndex(2)}/>}

          <Box sx={{width: 1, '& p': {lineHeight: 1.5}}}
               dangerouslySetInnerHTML={{__html: position?.description || ''}}/>
        </>}
        {tabIndex === 2 && <>
            <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>

              {/*@TODO refactor backend then ewview such conditions*/}
              {!!positionMatch?.expectedRate && <ContractorAlreadyApplied/>}

              {meetingSlotsAreAvailable === false && <SlotAvailabilityAlert/>}

                <EditableUserCalendar
                    onCalendarUpdate={scheduleUpdated}
                    contractorId={profile!!.contractorProfile!!.contractorId}
                />

              {!positionMatch?.expectedRate &&
                  <CandidatePositionApplication position={position!}
                                                meetingSlotsAreAvailable={meetingSlotsAreAvailable}
                                                positionMatchId={positionMatch?.id}/>}
            </Box>

        </>}
          <PositionApproveBottomNav index={tabIndex} onChange={setTabIndex}/>
      </>}

    </Container>
  </Loading>
}

export default ContractorPositionWidget;
