import {Box, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {GuestPerson, usePositionMatchQuery,} from "../../api/sdl";
import {DateTime} from "luxon";
import {AppointmentCalendar} from "./ui/AppointmentCalendar";
import {MeetingList, MeetingSetupForm} from "../../entity";
import {usePositionMatchCalendar} from "./api/usePositionMatchCalendar";
import {useScheduleMeeting} from "./api/useScheduleMeeting";
import Loading from "../../shared/components/Loading";
import {AvailabilityRequestButton} from "./ui/AvailabilityRequestButton";

interface Props {
  positionMatchId: string,
  onMeetingScheduled: () => void,
  onScheduleClick: () => void,
  meeting?: {meetingScheduledOn: string, id?: string, guests?: GuestPerson[]},
  meetingListTitle: string,
  slots?: {
    header?: JSX.Element,
  }
}

export function ScheduleMeeting(props: Props) {
  const {onMeetingScheduled, positionMatchId, onScheduleClick, meetingListTitle, meeting} = props;
  const [meetingDate, setMeetingDate] = React.useState<DateTime | undefined>(() => (
    meeting?.meetingScheduledOn ? DateTime.fromISO(meeting.meetingScheduledOn) : undefined
  ));
  const [startDate, setStartDate] = React.useState(DateTime.now().startOf('week'));
  const {slots, loading: slotsLoading, refetch: refetchCalendarSlots} = usePositionMatchCalendar(startDate, positionMatchId)

  const {data: positionMatchData, refetch: refetchPositionMatch} = usePositionMatchQuery({
    variables: {positionMatchId},
    fetchPolicy: 'no-cache'
  })

  const meetings = positionMatchData?.positionMatch.meetings || [];

  const refetch = () => {
    refetchCalendarSlots();
    refetchPositionMatch();
  }

  const {schedule, loading} = useScheduleMeeting(positionMatchId, () => {
    refetch();
    onMeetingScheduled();
  }, meeting?.id)

  const handleScheduleClick = async (guests: GuestPerson[]) => {
    if (meetingDate) {
      await schedule(guests, meetingDate.toISO()!);
    }
  }

  const onCancelMeeting = (meetingId: string) => {
    refetch();
    setMeetingDate(undefined)
  }

  useEffect(() => {
    if (meeting?.meetingScheduledOn) {
      setStartDate(DateTime.fromISO(meeting.meetingScheduledOn).startOf('week'));
      setMeetingDate(DateTime.fromISO(meeting.meetingScheduledOn))
    }
  }, [meeting]);

  return <Box sx={{display: 'flex', gap: 3, flexDirection:{xs: 'column', md: 'row'}}}>
    <Box>
      {props.slots?.header}

        <AppointmentCalendar onStartDateChange={setStartDate}
                             loading={slotsLoading}
                             startDate={startDate}
                             selectedDate={meetingDate}
                             slots={slots}
                             onClick={setMeetingDate}/>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flexShrink: 0}}>

      {meetingDate && (
        <MeetingSetupForm
          onCancel={() => setMeetingDate(undefined)}
          guests={meeting?.guests}
          onScheduled={(interviewValue) => {
            handleScheduleClick(interviewValue)
          }}
          meetingDate={meetingDate!!}/>
      )}

      {!meetingDate && <Box sx={{display: 'flex', width: '300px', height: '150px', alignItems: 'center'}}>
        <Box sx={{width: 1}}>

          <Typography sx={{textAlign: 'center'}}>
              Please, select an available time slot

          </Typography>
          <AvailabilityRequestButton positionMatchId={positionMatchId} />

        </Box>
      </Box>}

      {loading && <Loading in height={50}/>}

      <MeetingList meetings={meetings}
                   onScheduleClick={onScheduleClick}
                   onCancelMeeting={onCancelMeeting}
                   positionMatchId={positionMatchId}
                   showNextMeetingButton={false}
                   title={meetingListTitle} />

    </Box>
  </Box>
}
