import React from "react";
import {AttributeCategory, PositionOutput} from "../../../api/sdl";
import {Box, Divider, Stack, Typography} from "@mui/material";
import AttributesChips from "../../../shared/components/AttributesChips";
import Param from "../../../shared/components/Param";
import {showRange, toCurrency} from "../../../shared";
import * as _ from "lodash";

export const PositionViewCurrentExperience = React.memo(({position}: { position?: PositionOutput }) => (
  <Box sx={{background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px'}}>
    <Stack spacing={3}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between', width: 1}}>
        <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>Current experience</Typography>
      </Box>
      <AttributesChips sx={{mt: 1, maxWidth: 200}} color={'info'}
                       attributes={position?.attributesExpanded.filter(a => a.category === AttributeCategory.RoleCategory).map(a => a.attribute)}/>
      {!!position?.attributesExpanded.filter(a => a.category === AttributeCategory.CallPointSpeciality)?.length && <>
          <Divider/>
          <Param title={'Call point specialities'} content={<AttributesChips sx={{mt: 1, maxWidth: 200}} color={'info'}
                                                                             attributes={position?.attributesExpanded.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.attribute)}/>}/>
      </>}
      <Divider/>
      <Param title={'Salary'} content={<Typography sx={{
        color: '#8A8C8D',
        fontWeight: 500
      }}>{showRange(position?.salaryRangeMin, position?.salaryRangeMax, undefined, undefined, toCurrency)} {!!position?.salaryRangeType && _.startCase(_.toLower(position?.salaryRangeType))}</Typography>}/>
    </Stack>
  </Box>
))