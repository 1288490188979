import {useIsAdmin} from "../../../shared";
import React from "react";
import {Box} from "@mui/material";
import {Check} from "phosphor-react";
import {useToggleEmploymentHistoryVerificationMutation} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";

interface Props {
  verified?: boolean
  id: string
}
export function EmploymentHistoryVerified({id, verified}: Props) {
  const isAdmin = useIsAdmin();

  const [request, {loading, called}] = useToggleEmploymentHistoryVerificationMutation()

  const handleClick = async () => {
    await request({variables: {id}})
  }
  const color = verified ? "#21bf5b": "#666"
  if(!isAdmin) {
    return null
  }

  if(isAdmin && !called) {
    return <Box>
      <LoadingButton loading={loading} startIcon={<Check color={color} size={16} weight="fill" />} size={"small"} sx={{color: color}} onClick={handleClick}>Verified</LoadingButton>
    </Box>
  }

  return null;
}