import {SalaryRangeType} from "../../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import React, {useEffect} from "react";
import InfoCard from "../../../shared/components/InfoCard";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import TableRow from "../../../shared/components/TableRow";
import {showRange, toCurrency} from "../../../shared/utils";
import * as _ from "lodash";
import {LoadingButton} from "@mui/lab";

interface AcceptFormProps {
  salaryRangeMax?: number | null,
  salaryRangeMin?: number | null,
  salaryRangeType?: SalaryRangeType | null,
  loading: boolean,
  disabled: boolean,
  onSubmit: (contractorExpectedRate?: number) => void,
  onDecline?: () => void,
}

const AcceptingForm = (props: AcceptFormProps) => {
  const {onDecline, loading, salaryRangeMin, salaryRangeMax, salaryRangeType, onSubmit, disabled} = props;
  const formik = useFormik({
    initialValues: {
      salaryRangeMin: undefined,
      salaryRangeMax: undefined,
      contractorExpectedRate: undefined,
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        onSubmit(values.contractorExpectedRate)
      }
    },
    validateOnChange: true
  });

  useEffect(() => {
    formik.setValues({
      salaryRangeMax: salaryRangeMax as any,
      salaryRangeMin: salaryRangeMin as any,
      contractorExpectedRate: undefined
    })
  }, [salaryRangeMax, salaryRangeMin]);

  return <form onSubmit={formik.handleSubmit}>
    <InfoCard
      hideIcon
      title={<Typography sx={{fontSize: 16, whiteSpace: 'pre-line', color: '#000'}}>
        Enter the <b>{salaryRangeType?.toLowerCase()} rate</b> you would accept for this role. The salary range shows the <b>{salaryRangeType?.toLowerCase()} rate</b> range the company is offering (this is the base rate and does not include any commission or bonus). Once you enter your preferred rate, click <b>Accept</b>. The company will receive your rate when they view your experience and profile.
      </Typography>}
      content={<Box sx={{width: 1, display: 'flex', flexDirection: 'column', gap: 2}}>
        <TableRow image={`/img/teenyicons/money.svg`}
                  title={'Salary range:'}
                  content={(
                    <Typography sx={{color: 'text.primary', ml: 2}} >
                      {showRange(salaryRangeMin, salaryRangeMax, undefined, undefined, toCurrency)}{!!salaryRangeType && `/${_.toLower(salaryRangeType)}`}
                    </Typography>
                  )}
        />
        <Box sx={{width: 1, maxWidth: 365}}>
          <FormControl>
            <FormLabel>{_.startCase(salaryRangeType?.toLowerCase())} rate you would accept for this role:</FormLabel>
            <TextField placeholder="Enter expected rate"
                       name="contractorExpectedRate"
                       data-test='expected-rate'
                       InputProps={{
                         startAdornment: <InputAdornment position="start">$</InputAdornment>,
                       }}
                       type={'number'}
                       value={formik.values.contractorExpectedRate}
                       onChange={formik.handleChange}
                       error={formik.touched.contractorExpectedRate && Boolean(formik.errors.contractorExpectedRate)}
            />
            {(formik.touched.contractorExpectedRate && formik.errors.contractorExpectedRate) && <FormHelperText sx={{color: '#D3232F'}}>
              {mapMessage(formik.errors.contractorExpectedRate, salaryRangeType?.toLowerCase())}
            </FormHelperText>}
          </FormControl>
        </Box>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'space-between'}}>
          <Box>
            {!!onDecline && <Button color={'error'} variant={'contained'} onClick={onDecline}>Decline</Button>}
          </Box>
          <LoadingButton disabled={disabled} variant={'contained'} data-test='apply' loading={loading} type={'submit'}>Accept</LoadingButton>
        </Box>
      </Box>}
    />
  </form>
}

const mapMessage = (message?: string, rate?: string) => {
  if (message === 'RATE_MESSAGE') {
    return `The ${rate} rate you entered is outside the pay range for this role. Consider changing your rate for an opportunity to interview.`
  }
  return message
}

const schemaFields: any = {
  contractorExpectedRate: yup
    .number().min(0, 'Should not be negative')
    .required('Expected Rate is required')
    .test(
      'negativeDifference',
      'RATE_MESSAGE',
      function(item){
        if(this.parent.salaryRangeMax == undefined) {
          return item <= this.parent.salaryRangeMin
        } else {
          return item >= this.parent.salaryRangeMin && item <= this.parent.salaryRangeMax
        }
      }
    )
}


export {AcceptingForm};
