import * as _ from "lodash";
import {DateTime} from "luxon";
import {Alert, Box, Chip, Typography} from "@mui/material";
import React from "react";
import {PositionFormApiData} from "../../position-form/lib/types";
import {PositionStatus} from "../../../api/sdl";

interface Props {
  position: PositionFormApiData
}

export function PositionStatusView({position}: Props) {
  if(position?.status === PositionStatus.Draft) {
    return <Box sx={{flexDirection: "row", display: "flex", justifyContent: "space-between", alignItems: "flex-end"}} >
      <Chip label="Draft" color={"error"} size={"medium"}/>
        <Alert sx={{my: 0, fontWeight: 500, fontSize: 14}} variant={'standard'} color={'error'}>
          Role is not currently live.
          In order to start the matching process click <b>Publish</b>.
        </Alert>
    </Box>
  }

  return <Typography variant={'body2'}>
    {_.upperFirst(_.lowerCase(position?.status).replace('open', 'published'))}
    {position?.status === PositionStatus.Open && ', ' + DateTime.fromISO(position?.publishedAt).toFormat('LLL d')}
  </Typography>
}