import React, { PropsWithChildren} from "react";
import {
  PositionOutput, useContractorPositionByIdQuery,
} from "../../../api/sdl";
import useCalendarAvailability from "../../../shared/hooks/useCalendarAvailability";
import {ContractorPositionMatch} from "./lib/types";


interface PositionMatchContextProviderProps {
  positionId: string,
  contractorId: string,
}

interface PositionMatchContextProviderValue {
  positionId: String,
  position?: PositionOutput,
  meetingSlotsAreAvailable?: boolean,
  scheduleUpdated: () => void,
  loading: boolean,
  positionMatch?: ContractorPositionMatch | null,
}

const defaultValue: PositionMatchContextProviderValue = {
  positionId: 'N/A',
  position: undefined,
  scheduleUpdated: () => {},
  loading: true
};

export const PositionMatchContext = React.createContext<PositionMatchContextProviderValue>(defaultValue);

export const PositionMatchContextProvider = ({children, positionId, contractorId}: PropsWithChildren<PositionMatchContextProviderProps>) => {

  const [inc, setInc] = React.useState(0);
  const [loading, setLoading] = React.useState(defaultValue.loading);
  const [position, setPosition] = React.useState<PositionOutput>();
  const [positionMatch, setPositionMatch] = React.useState<ContractorPositionMatch | null>();

  const {data: positionData, refetch} = useContractorPositionByIdQuery({fetchPolicy: 'no-cache', variables: {positionId}, onCompleted: (data) => {

    if(data?.contractorPositionMatchByPositionId !== undefined) {
      setPositionMatch(data?.contractorPositionMatchByPositionId || null)
    } else {
      setPositionMatch(null)
    }
  }});

  const {meetingSlotsAreAvailable} = useCalendarAvailability({contractorId, companyId: positionData?.positionById?.company?.id, inc: inc});

  const scheduleUpdated =() => {
    console.log('scheduleUpdated CALLBACK');
    setInc(inc + 1);
  };

  React.useMemo(() => {

    if(positionData?.positionById !== undefined && meetingSlotsAreAvailable !== undefined && positionMatch !== undefined) {
      setLoading(false);
      setPosition(positionData!.positionById!);
    } else {
      console.log('positionData', positionData, 'meetingSlotsAreAvailable', meetingSlotsAreAvailable, 'positionMatch', positionMatch)
    }
  }, [positionData, meetingSlotsAreAvailable, positionMatch]);


  return <PositionMatchContext.Provider value={{
    positionId,
    position: position,
    meetingSlotsAreAvailable,
    scheduleUpdated,
    loading,
    positionMatch
  }}>
    {children}
  </PositionMatchContext.Provider>
};
